var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-expansion-panel",
    _vm._g({}, _vm.$listeners),
    [
      _c(
        "v-expansion-panel-header",
        {
          scopedSlots: _vm._u([
            {
              key: "actions",
              fn: function () {
                return [
                  _c("v-icon", { staticClass: "icon", attrs: { left: "" } }, [
                    _vm._v(" mdi-chevron-down "),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("div", { staticClass: "font-weight-bold header" }, [
            _vm._v("Modal"),
          ]),
        ]
      ),
      _c(
        "v-expansion-panel-content",
        [
          _c("color-input-row", {
            attrs: { title: "Modal background color", "line-number": "1" },
            model: {
              value: _vm.value.backgroundColor,
              callback: function ($$v) {
                _vm.$set(_vm.value, "backgroundColor", $$v)
              },
              expression: "value.backgroundColor",
            },
          }),
          _c("v-divider"),
          _c("color-input-row", {
            attrs: { title: "Modal text color", "line-number": "2" },
            model: {
              value: _vm.value.textColor,
              callback: function ($$v) {
                _vm.$set(_vm.value, "textColor", $$v)
              },
              expression: "value.textColor",
            },
          }),
          _c("v-divider"),
          _c("color-input-row", {
            attrs: { title: "Left button text color", "line-number": "3" },
            model: {
              value: _vm.value.leftButtonTextColor,
              callback: function ($$v) {
                _vm.$set(_vm.value, "leftButtonTextColor", $$v)
              },
              expression: "value.leftButtonTextColor",
            },
          }),
          _c("v-divider"),
          _c("color-input-row", {
            attrs: { title: "Left button border color", "line-number": "4" },
            model: {
              value: _vm.value.leftButtonBorderColor,
              callback: function ($$v) {
                _vm.$set(_vm.value, "leftButtonBorderColor", $$v)
              },
              expression: "value.leftButtonBorderColor",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }