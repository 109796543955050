var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "field-row",
    {
      attrs: {
        title: _vm.title,
        tooltip: _vm.tooltip,
        "line-number": _vm.lineNumber,
        "hide-line-number": _vm.hideLineNumber,
      },
    },
    [
      _c(
        "v-color-input",
        _vm._g(
          _vm._b(
            {
              staticClass: "color-input",
              attrs: {
                label: _vm.inputLabel || _vm.$attrs.value,
                mode: "hex",
                elevation: "2",
                "no-alpha": "",
                "hide-mode-switch": "",
              },
            },
            "v-color-input",
            _vm.$attrs,
            false
          ),
          _vm.$listeners
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }